import { mapState } from "vuex";
import { getdelegateList, gotolink, updateConsultLog, getFile,logoffConfirm} from "./api";
import util from "./components/until";
import sortBar from "./components/SortBar";
import { publicPath } from "@/common/constant.js";
import { readOnly } from "@/views/center/noticeList/api";

export default {
  components: {
    sortBar
  },
  name: "index",
  data() {
    return {
      pageLoadFlag: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/todoList",
          name: "todoList",
          title: "待办"
        }
      ],
      windowList: [],
      sortList: [],
      noticeList: [],
      filterFrom: {
        pageNo: 1,
        // currentPage:1,
        status: 0,
        typeValue: '',
        content: "",
        pageSize: 20,
        orderBy: 'desc',
      },
      isSearch: true,
      total: '',//搜索到几条数据
      pending: '',//待处理
      refesh: "refesh",
      totalPages: '',//数据总条数
      currentPage: '',//当前页数
      pageSize: 20,
      messageInfo: '',
      onluyOneMessage: false,
      visibleAgree: false,
      visibledisAgree: false,
      todoStatus: '',
      todoId: '',
      message: '',
      ChannelOrderTip: false,
      waringTip: false,
      waringinfo: '',
      logoffShow: false,
      logoffId: '',
      logoffState: 0, //1驳回 2同意
    };
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
    }),
  },
  mounted() {
    //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    this.filterFrom = { ...this.filterFrom, formCode: this.filterConfigStr ? this.filterConfigStr : '' };
    this.sortList = util.sortList;
    // this.getMessageFilterList()
    // this.getTodoList ()

  },
  methods: {
    // 跳转链接
    gotoUrl(item,id) {
      if(item.typeCode == '141706814751'){
        // const {date} = JSON.parse(item.sourceUrl)
        // let routeUrl = this.$router.resolve({
        //   path: "/policyRebate/todoDetail",
        //   query: { id: item.sourceId,sourceUrl:item.sourceUrl }
        // });
        // window.open(routeUrl.href, "_blank");
        this.$router.push({
          path: "/policyRebate/todoDetail",
          query: { id: item.sourceId,sourceUrl:item.sourceUrl, isRead: item.status == '1' ? true : false }
        })
        return
      }
      // 旧机换新
      if (item.typeCode=='15411758459') {
        let routeUrl = this.$router.resolve({
          path: "/tradeInoldForNewDetail",
          query: { id: item.sourceUrl }
        });
        window.open(routeUrl.href, "_blank");
        return
      }

      // 如果是维修单待办 跳转工单提报

      if (item.typeCode=='187212') {
        if (item.status == '0') {
          let routeUrl = this.$router.resolve({
            path: "/installationRepairReporting/workOrderSubmission",
            query: { id: item.sourceUrl,type:4 }
          });
          window.open(routeUrl.href, "_blank");
        }
        return;
      }

        // 合同待办 (单独处理 调接口，成功的话应该返回链接  跳转页面  要是失败弹出错误信息)
      if (item.typeCode == 14170681476) {
        let data = {
          id: id,
          type: 'docView'
        }
        gotolink(data).then(res => {
          if(res.data.code == 0) {
            window.open(res.data.url, "_blank");
          } else {
            this.ChannelOrderTip = true
            this.message = res.data.msg
          }

        })
        return;
      }
      // 巡店待办 单独处理 item.status == '0' 未处理
      if (item.typeCode == 14173612880 && item.status == '0') {
        this.ChannelOrderTip = true
        this.message = '请到信天翁移动端执行巡店任务!'
        return;
      }
      //巡店待办 item.status == '1' 已处理
      if (item.typeCode == 14173612880 && item.status == '1') {
        let routeUrl = this.$router.resolve({
          path: "/center/inspection",
          query: { id: item.sourceId },
        });
        window.open(routeUrl.href, "_blank");
        return
      }
      // 如果是退货待办跳转到退货详情的页面 单独处理
      if (item.typeCode == 14182051644) {
        if(item.taskStatus == '代理商确认') {
          let routeUrl = this.$router.resolve({
            path: "/hisenseReturn/defectiveReturnListNew",
            query: { id: item.sourceId },
          });
          window.open(routeUrl.href, "_blank");
          return
        } else {
          let routeUrl = this.$router.resolve({
            path: "/hisenseReturn/ReturnApplicationDetails",
            query: { id: item.sourceId },
          });
          window.open(routeUrl.href, "_blank");
          return
        }
      }
      // 整改待办 单独处理 未处理提示
      if (item.typeCode == 14173612881 && item.status == '0') {
        this.ChannelOrderTip = true
        this.message = '请到信天翁移动端执行整改任务!'
        return;
      }
      //整改待办 已处理跳转
      if (item.typeCode == 14173612881 && item.status == '1') {
        let routeUrl = this.$router.resolve({
          path: "/center/inspection",
          query: { id: item.sourceId },
        });
        window.open(routeUrl.href, "_blank");
        return
      }
      // 建店建户待办 单独处理
      if( item.typeCode == 14187583093) {
        // 建门店
        if(item.content.indexOf('门店') > -1) {
          let routeUrl = this.$router.resolve({
            path: "/basicInfo?type=7",
          });
          window.open(routeUrl.href, "_blank");
          return
        }

        let routeUrl = this.$router.resolve({
          path: "/basicInfo?type=3",
        });
        window.open(routeUrl.href, "_blank");
        return
      }
      // 考核通知单 单独处理
      if (item.typeCode == 14182987654) {
        if(item.sourceUrl == 'SINGLE') {
          let routeUrl = this.$router.resolve({
            path: "/assessmentNotice/detailZD",
            query: { id: item.sourceId },
          });
          window.open(routeUrl.href, "_blank");
          return
        } else {
          let routeUrl = this.$router.resolve({
            path: "/assessmentNotice/detail",
            query: { id: item.sourceId },
          });
          window.open(routeUrl.href, "_blank");
          return
        }
      }
      // 新增门店待办 单独处理
      if (item.typeCode == 14187583090) {
        this.ChannelOrderTip = true
        this.message = '请到信天翁移动端处理！'
        return;
      }

      if (item.typeCode == 14173612879) {
        let routeUrl = this.$router.resolve({
          path: "/center/tododetail/detail?notHome='1'",
          query: {  id:item.id,status:item.status}
        });
        window.open(routeUrl.href, "_blank");
        return
      }
      // 专卖店待办 单独处理 (待处理状态)
      if (item.typeCode == 15276663895) {
        if(item.status == '0'){
          let routeUrl = this.$router.resolve({
            path: "/storeActivities/oreditUpload",
            query: { sourceUrl: item.sourceUrl, id:item.id }
          });
          window.open(routeUrl.href, "_blank");
        }
        return
      }
      // 代理商待办

      if (item.typeCode == 15411758458) {
        // console.log('2222',JSON.parse(item.sourceUrl))
        const {id} = JSON.parse(item.sourceUrl)
        if(item.status == '0'){
          let routeUrl = this.$router.resolve({
            path: "/agentVerificationActivities/writeOff",
            query: {
              type: 'hexiao',
              id:id,
              sourceUrl:'todoList'
            }
          });
          window.open(routeUrl.href, "_blank");
        }
        return
      }


      // 后端返回带# 是跳转系统内的链接  替换掉需要的参数
      // 未带# 跳转系统外链接 拼接地址跳转
      // 如果返回none 不能跳转任何操作
      if(item.targetUrl == 'none') {
        return
      }
      if(item.targetUrl.indexOf('#') !== -1) {
        // 替换掉接口返回的变量
        item.targetUrl = item.targetUrl.replace('${content}',item.content)
        item.targetUrl = item.targetUrl.replace('${id}',item.id)
        let routeUrl = this.$router.resolve({
          path: item.targetUrl.split('#')[1],// 截取#后的链接进行跳转
        });
        window.open(routeUrl.href, "_blank");
      } else {
        const BASE_API = publicPath;
        let url = BASE_API + item.targetUrl
        window.open(url, "_blank");
      }
    },
    // 预览
    openView(id,item) {
      if (item.typeCode == 14170681475) {
        let data = {
          id: id,
          type: 'docView'
        }
        gotolink(data).then(res => {
          this.ChannelOrderTip = true
          this.message = res.data.msg
        })
      } else {
        let data = {
          id: id,
          type: 'docView'
        }
        gotolink(data).then(res => {
          if (res.data.url) {
            window.open(res.data.url, "_blank");
          } else {
            this.ChannelOrderTip = true
            this.message = res.data.msg
          }
        })
      }

    },
    // 下载
    downFile(id, item, staute) {
      if (item.typeCode == 14170681475) {
        this.ChannelOrderTip = true
        this.message = '请登录信天翁小程序或者前往自动对账系统进行处理'
        return;
      } else {
        const BASE_API = publicPath;
        window.location.href = BASE_API + "/task/downFile.nd?id=" + id;
      }

    },
    //获取列表
    async getTodoList() {
      this.pageLoadFlag = true;
      try {
        const res = await getdelegateList(this.filterFrom);
        this.noticeList = res.data.priceDelegateMessageList
        this.total = res.data.totalCount
        let pendingList = []
        res.data.priceDelegateMessageList.forEach(item => {
          if (item.status == '0') {
            pendingList.push(item)
          }


        });
        this.pending = res.data.waitCount
        this.totalPages = res.data.totalPages
        this.currentPage = res.data.currentPage
        this.pageLoadFlag = false;
      } catch (error) {
        console.log(error);
      }
    },

    onFilterChange(checkedObj) {
      this.filterFrom.pageNo = 1
      //合并入参请求对象
      this.filterFrom = { ...this.filterFrom, ...checkedObj[0] };
      this.totalPages = 1
      this.currentPage = 1
      //查询列表
      this.getTodoList()
    },
    //筛选排序
    sortClick(data) {
      const { sortStatus } = data;
      this.filterFrom = {
        ...this.filterFrom,
        pageNo: 1,
        orderBy: sortStatus == "" ? "" : sortStatus == 1 ? "desc" : "asc"
      };
      this.getTodoList()
    },
    // 搜索
    onSearch(content) {
      this.noticeList = [];
      this.filterFrom.pageNo = 1
      this.filterFrom = { ...this.filterFrom, content };
      this.getTodoList();
      this.isSearch = false
    },
    // 加载更多 
    loadMore() {
      // alert(1)
      this.filterFrom = {
        ...this.filterFrom,
        pageNo: this.filterFrom.pageNo + 1
      };
      this.pageLoadFlag = true;
      getdelegateList(this.filterFrom).then(res => {
        // 点击加载更多 拼接数组
        this.noticeList = this.noticeList.concat(res.data.priceDelegateMessageList)
        this.pageLoadFlag = false;
        this.totalPages = res.data.totalPages
        this.currentPage = res.data.currentPage
      }).catch(error => {
        console.log(error)
      })
    },
    // 打开意见征询弹窗
    openAgree(id, status) {
      this.visibleAgree = true
      this.todoId = id
      this.todoStatus = status
    },
    // 打开意见征询弹窗
    opendisAgree(id, status) {
      this.visibledisAgree = true
      this.todoId = id
      this.todoStatus = status
    },
    // 同意
    agree() {
      updateConsultLog({ id: this.todoId, status: this.todoStatus }).then(res => {
        if (res.data.code == 200) {
          this.onluyOneMessage = true
          this.message = res.data.content
        } else {
          this.onluyOneMessage = true
          this.message = res.data.msg
        }
        this.getTodoList()
      }).catch(error => {
        console.log(error)
      })

    },
    // 不同意
    reject() {
      updateConsultLog({ id: this.todoId, status: this.todoStatus }).then(res => {
        if (res.data.code == 200) {
          this.onluyOneMessage = true
          this.message = res.data.content
        }
        this.getTodoList()
      }).catch(error => {
        console.log(error)
      })
    },
    // 注销用户弹窗
    logOff(id,state){
      this.logoffId = id;
      this.logoffShow = true;
      this.logoffState = state;
    },
    // 注销确认
    logoffConfirm() {
      let data = {
        taskId: this.logoffId,
        type: this.logoffState == 1 ?'disagree' :'agree'
      }
      // return
      logoffConfirm(data).then(res => {
        this.totalPages = 1
        this.currentPage = 1
        //查询列表
        this.getTodoList()
      })
    },
    // 点击刷新
    refeshTodoList(content) {
      this.filterFrom.pageNo = 1
      this.filterFrom = { ...this.filterFrom, content };
      this.getTodoList()
    },
    change(content) {
      this.filterFrom.pageNo = 1
      this.filterFrom = { ...this.filterFrom, content };
    },

  }
}


